import * as React from 'react';
import { RouterProvider } from 'react-router';
import AppProviders from "./core/AppProviders";
import {router} from "./router";

export const Root: React.FC = () => (
  <AppProviders>
    <RouterProvider router={router} />
  </AppProviders>
);
