import React, {useEffect, useState} from 'react';
import {Navbar} from "../components/Navbar";
import {AbsoluteWrapper, Headline, LogoWrapper, PrimaryMiscButtonWrapper, PrimaryMiscDiv} from "./LandingPage.styled";
import misc1 from "../assets/miscs/1.png";
import {Button} from "@mui/material";
import {AdvancesSection} from "../components/Advances";
import ContactDialog from "../components/ContactDialog";
import SendIcon from '@mui/icons-material/Send';
import OfferSection from "../components/OfferSection";
import ImageSlider from "../components/ImageSlider";
import GoogleMapComponent from "../components/GoogleMapCmp";
import ImageSection from "../components/ImageSection";
import Logo from "../assets/logo.png";

const LandingPage: React.FC = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isMessageSent, setMessageSent] = useState<boolean>(false);
    const handleMessageSent = () => {
        setMessageSent(true);
    };
    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        handleResize(); // Wywołanie przy renderowaniu komponentu
        window.addEventListener('resize', handleResize); // Nasłuchiwanie zmiany rozmiaru okna

        return () => {
            window.removeEventListener('resize', handleResize); // Czyszczenie event listenera przy odmontowywaniu komponentu
        };
    }, []);
    return (
        <div>
            <Navbar/>
            <PrimaryMiscDiv>
                <img src={misc1} alt="Primary"/>
                <AbsoluteWrapper>
                    <Headline>My pracujemy</Headline>
                    <Headline>Ty pijesz kawę</Headline>
                    <LogoWrapper>
                        <img src={Logo} alt="Logo"/>
                    </LogoWrapper>
                    <PrimaryMiscButtonWrapper>
                        <Button size={isSmallScreen ? "small" : "large"}
                                sx={{textTransform: 'none', fontSize: "19px", fontWeight: 500}}
                                endIcon={<SendIcon/>} onClick={handleOpenDialog} disabled={isMessageSent}
                                variant="contained">Wyślij wiadomość</Button>
                    </PrimaryMiscButtonWrapper>
                </AbsoluteWrapper>
            </PrimaryMiscDiv>
            <section id="about">
                <AdvancesSection/>
                <ImageSection/>
            </section>
            <section id="offer">
                <OfferSection/>
            </section>
            <section id="certificates">
                <ImageSlider/>
            </section>
            <section id="contact">
                <GoogleMapComponent/>
            </section>
            <ContactDialog open={dialogOpen} onClose={handleCloseDialog} onMessageSent={handleMessageSent}/>
        </div>
    );
};

export default LandingPage;
