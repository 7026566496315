import React, {createContext, ReactNode, useEffect, useState} from 'react';
import {churchEntitiesMock} from "../mocks/church-entity-list";
import {ChurchEntity} from "../models/church-entity.model";

interface ChurchContextProps {
    entities: ChurchEntity[];
    loading: boolean;
    getEntityById: (id: number) => ChurchEntity | undefined;
}

const ChurchContext = createContext<ChurchContextProps | undefined>(undefined);

export const ChurchProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [entities, setEntities] = useState<ChurchEntity[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setEntities(churchEntitiesMock);
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getEntityById = (id: number): ChurchEntity | undefined => {
        return entities.find(entity => entity.id === id);
    };

    return (
        <ChurchContext.Provider value={{entities, loading, getEntityById}}>
            {children}
        </ChurchContext.Provider>
    );
};

export const useChurch = () => {
    const context = React.useContext(ChurchContext);
    if (context === undefined) {
        throw new Error('useChurch must be used within a ChurchProvider');
    }
    return context;
};
