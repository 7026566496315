import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        background: {
            default: '#fbfbfb',
        },
        primary: {
            main: '#937d50',
        },
        secondary: {
            main: '#bfa05b',
        },
    },
});

export default theme;
