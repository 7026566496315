import {createBrowserRouter, createRoutesFromElements, Navigate, Route} from "react-router-dom";
import React from "react";
import LandingPage from "./pages/LandingPage";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route path="*" element={<Navigate to="/" replace/>}/>
            <Route path="/" Component={LandingPage}/>
        </Route>
    )
);
