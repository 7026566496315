import React, {useState} from 'react';
import {Box, Link, Paper, Switch, Typography} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const containerStyle = {
    width: '100%',
    height: '600px',
    borderRadius: '8px',
    boxShadow: '0 3px 40px rgba(0, 0, 0, 0.15)',
    border: '1px solid rgba(0, 0, 0, 0.25)',
};

const center = {
    lat: 53.161541,
    lng: 16.7653566,
};


const GoogleMapComponent: React.FC = () => {

    const [map, setMap] = useState<any>(null);
    const [mapVisible, setMapVisible] = useState(false);

    const onLoad = React.useCallback(function callback(map: any) {
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null);
    }, []);

    const handleMapToggle = () => {
        setMapVisible((prev) => !prev);
    };

    return (
        <Box>
            <Typography variant="h3"
                        sx={{marginBottom: '84px', marginTop: '64px', fontWeight: 'bold', textAlign: 'center'}}>
                Lokalizacja i Kontakt
            </Typography>
            <Box sx={{position: 'relative', width: '100%', height: '600px', marginBottom: '16px', marginTop: '36px'}}>
                {/*{isLoaded && (*/}
                {/*    <GoogleMap*/}
                {/*        mapContainerStyle={containerStyle}*/}
                {/*        center={center}*/}
                {/*        zoom={15}*/}
                {/*        onLoad={onLoad}*/}
                {/*        onUnmount={onUnmount}*/}
                {/*        options={{*/}
                {/*            mapTypeControl: false,*/}
                {/*            streetViewControl: false,*/}
                {/*            fullscreenControl: false,*/}
                {/*            gestureHandling: mapVisible ? 'auto' : 'none',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <Marker position={center}/>*/}
                {/*    </GoogleMap>*/}
                {/*)}*/}
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9568.356533533437!2d16.7677288!3d53.16244!3m2!1i1024!2i768!4f15!3m3!1m2!1s0x4703e69d05bf9a7d%3A0x8de42c5cab14ccb3!2sKossaka+98%2C+64-920+Pi%C5%82a!5e0!3m2!1spl!2spl!4v1444128316812"
                    width="100%"
                    title="Mapa"
                    height="100%"
                    style={{border: 0, minHeight: '400px'}}
                    allowFullScreen={true}
                    loading="lazy"
                    aria-hidden="false"
                />
                {/*<Box*/}
                {/*    sx={{*/}
                {/*        position: 'absolute',*/}
                {/*        top: '16px',*/}
                {/*        left: '16px',*/}
                {/*        padding: '12px',*/}
                {/*        backgroundColor: '#ffffff',*/}
                {/*        borderRadius: '8px',*/}
                {/*        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',*/}
                {/*        width: '250px',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Typography variant="h6" sx={{fontWeight: 'bold', marginBottom: '8px'}}>*/}
                {/*        ul. Kossaka 98*/}
                {/*    </Typography>*/}
                {/*    <Typography variant="body1">ul. Kossaka 98, 64-920 Piła</Typography>*/}
                {/*    <Link*/}
                {/*        href="https://www.google.com/maps?q=Kossaka+98,+64-920+Piła&hl=pl"*/}
                {/*        target="_blank"*/}
                {/*        rel="noopener"*/}
                {/*        sx={{display: 'block', marginTop: '16px', color: '#007bff', textDecoration: 'none'}}*/}
                {/*    >*/}
                {/*        Wyświetl większą mapę*/}
                {/*    </Link>*/}
                {/*    <Link*/}
                {/*        href="https://www.google.com/maps/dir/?api=1&destination=Kossaka+98,+64-920+Piła&travelmode=driving"*/}
                {/*        target="_blank"*/}
                {/*        rel="noopener"*/}
                {/*        sx={{display: 'block', color: '#007bff', marginTop: '4px', textDecoration: 'none'}}*/}
                {/*    >*/}
                {/*        Wskazówki dojazdu*/}
                {/*    </Link>*/}
                {/*</Box>*/}

                <Box
                    sx={{
                        position: 'absolute',
                        top: '16px',
                        right: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transform: 'translateX(50%)',
                        zIndex: 9000,
                        backgroundColor: 'white',
                        padding: '8px 16px',
                        borderRadius: '8px',
                        width: "230px",
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Switch
                        checked={!mapVisible}
                        onChange={handleMapToggle}
                        color="primary"
                        size="medium"
                        inputProps={{'aria-label': 'Toggle map visibility'}}
                    />
                    <Typography variant="body1" sx={{color: 'black', fontWeight: 600, marginLeft: '8px'}}>
                        {mapVisible ? 'Pokaż Informacje' : 'Włącz Mapę'}
                    </Typography>
                </Box>

                {!mapVisible && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 5,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Paper
                            sx={{
                                backgroundColor: '#ffffff',
                                borderRadius: '8px',
                                padding: '22px',
                                boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
                                maxWidth: '400px',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h5" sx={{fontWeight: 'bold', marginBottom: '28px'}}>
                                Skontaktuj się z nami
                            </Typography>
                            <Box sx={{display: 'flex', alignItems: 'center', marginBottom: '14px'}}>
                                <AccessTimeIcon sx={{marginRight: '20px', color: '#007bff'}}/>
                                <Typography variant="body1">
                                    Nasze biuro pracuje w godzinach:<br/>8.00 - 15.00
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center', marginBottom: '14px'}}>
                                <LocationOnIcon sx={{marginRight: '20px', color: '#007bff'}}/>
                                <Typography variant="body1">
                                    ul. Kossaka 98, 64-920 Piła
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center', marginBottom: '14px'}}>
                                <PhoneIcon sx={{marginRight: '20px', color: '#007bff'}}/>
                                <Typography variant="body1">67 212-31-17</Typography>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <EmailIcon sx={{marginRight: '20px', color: '#007bff'}}/>
                                <Typography variant="body1">
                                    <Link href="mailto:biuro@profit-pila.pl" sx={{color: '#007bff'}}>
                                        biuro@profit-pila.pl
                                    </Link>
                                </Typography>
                            </Box>
                        </Paper>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default GoogleMapComponent;
