import styled from 'styled-components';

export const NavbarDiv = styled.div<{ isScrolled: boolean }>`
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    display: grid;
    grid-template-columns: 120px 1fr;
    background-color: #ffffff;
    padding: ${({isScrolled}) => (isScrolled ? '8px 16px' : '16px 24px')};
    color: black;
    border: 1px solid #e8e8e8;
    border-radius: 0 0 3px 3px;
    font-size: ${({isScrolled}) => (isScrolled ? '14px' : '16px')};
    box-shadow: ${({isScrolled}) => (isScrolled ? '0px 5px 20px -15px rgba(0, 0, 0, 0.2)' : '0px 7px 33px -22px rgba(210, 210, 210, 1)')};
    transition: all 0.3s ease;
    @media (max-width: 1440px) {
    }
    @media (max-width: 1024px) {
        grid-template-columns: 74px 1fr;
        padding: 4px;
    }
    @media (max-width: 768px) {
    }
`;

export const LogoImage = styled.div`
    img {
        @media (max-width: 1024px) {
            height: 26px;
            width: 100% !important;
        }
    }

    align-self: center;
    justify-self: start;
    display: flex;
    align-items: center;
`;

export const NavLinks = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-75px);
    gap: 12px;
    @media (max-width: 1024px) {
        gap: 6px;
        font-size: 12px;
        transform: translateX(0);
        & > * {
            padding: 0;
        }
    }
`;
