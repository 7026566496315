import styled from "styled-components";

export const PrimaryMiscDiv = styled.div`
    position: relative;
    top: -30px;

    img {
        width: 100%;
        object-fit: contain;
    }

    @media (max-width: 1440px) {
        img {
            height: 100%;
            width: 100%;
        }
    }
    @media (max-width: 1024px) {
        height: 465px;
    }
`

export const AbsoluteWrapper = styled.div`
    position: absolute;
    top: 31%;
    right: 26%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1440px) {
        top: 28%
    }
    @media (max-width: 1024px) {
        top: 25%;
    }
    @media (max-width: 768px) {
        top: 32%;
    }
`

export const PrimaryMiscButtonWrapper = styled.div`
    @media (max-width: 1440px) {
    }
    @media (max-width: 1024px) {
    }
`

export const LogoWrapper = styled.div`
    margin-top: 22px;
    margin-bottom: 22px;

    img {
        width: 280px;
        height: 130px;
    }

    @media (max-width: 1440px) {
        margin-top: 12px;
        margin-bottom: 12px;
        img {
            width: 220px;
            height: 100px;
        }
    }
    @media (max-width: 1024px) {
        margin-top: 8px;
        margin-bottom: 8px;
        img {
            width: 150px;
            height: 70px;
        }
    }
    @media (max-width: 768px) {

        img {
            position: relative;
            right: 41px;
            width: 110px;
            height: 40px;
        }
    }
`

export const Headline = styled.div`
    color: #5e3219;
    font-size: 45px;
    font-weight: 500;
    width: 100%;
    text-align: start;
    font-family: 'Comic Sans MS';
    line-height: 1.2;
    @media (max-width: 1440px) {
        font-size: 32px;
    }
    @media (max-width: 1024px) {
        font-size: 27px;
    }
    @media (max-width: 768px) {
        font-size: 21px;
    }
`
