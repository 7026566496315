import React from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import theme from "../theme/theme";
import {Children} from "../models/base.models";
import {ChurchProvider} from "../contexts/ChurchContext";
import {ThemeProvider as SCThemeProvider} from 'styled-components';


const AppProviders = ({children}: Children): React.ReactElement => (
    <ChurchProvider>
        <SCThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
        </SCThemeProvider>
    </ChurchProvider>
)
export default AppProviders;
