import React from 'react';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MessageIcon from '@mui/icons-material/Message';
import {AdvancesContainer, AdvancesItem, AdvancesText, AdvancesTitle, IconWrapper} from "./Advances.styled";

const advancesData = [
    {
        icon: <HandshakeIcon sx={{color: 'white', width: 50, height: 50}}/>,
        title: 'Współpraca',
        content: 'Zobowiązujemy się do wykonywania zadań z pełnym zaangażowaniem i zgodnie z obowiązującymi normami prawnymi. Nasze podejście jest indywidualne, by zapewnić jak najlepsze rezultaty współpracy.'
    },
    {
        icon: <AccessTimeIcon sx={{color: 'white', width: 52, height: 52}}/>,
        title: 'Terminowość',
        content: 'Nasza praca opiera się na odpowiedzialności i sumienności, co gwarantuje dotrzymywanie ustalonych terminów i pełne zadowolenie klienta.'
    },
    {
        icon: <MessageIcon sx={{color: 'white', width: 45, height: 45}}/>,
        title: 'Doradztwo',
        content: 'Oferujemy kompleksowe wsparcie w procesie zakładania własnej działalności, pomagając w dopełnieniu wszelkich niezbędnych formalności.'
    }
];

export const AdvancesSection: React.FC = () => {
    return (
        <AdvancesContainer>
            {advancesData.map((item, index) => (
                <AdvancesItem key={index}>
                    <IconWrapper>
                        {item.icon}
                    </IconWrapper>
                    <AdvancesTitle>{item.title}</AdvancesTitle>
                    <AdvancesText>{item.content}</AdvancesText>
                </AdvancesItem>
            ))}
        </AdvancesContainer>
    );
};
