import React, {useEffect, useState} from 'react';
import {LogoImage, NavbarDiv, NavLinks} from "./Navbar.styled";
import {Button} from "@mui/material";
import Logo from "../assets/logo.png";

export const Navbar: React.FC = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        handleResize(); // Wywołanie przy renderowaniu komponentu
        window.addEventListener('resize', handleResize); // Nasłuchiwanie zmiany rozmiaru okna

        return () => {
            window.removeEventListener('resize', handleResize); // Czyszczenie event listenera przy odmontowywaniu komponentu
        };
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollTo = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
    };

    return (
        <NavbarDiv isScrolled={isScrolled}>
            <LogoImage>
                <img src={Logo} alt="Logo" style={{width: isScrolled ? '75px' : '100px'}}/>
            </LogoImage>
            <NavLinks>
                <Button size={isSmallScreen ? "small" : isScrolled ? "medium" : "large"}
                        onClick={() => handleScrollTo('about')}>O Nas</Button>
                <Button size={isSmallScreen ? "small" : isScrolled ? "medium" : "large"}
                        onClick={() => handleScrollTo('offer')}>Oferta</Button>
                <Button size={isSmallScreen ? "small" : isScrolled ? "medium" : "large"}
                        onClick={() => handleScrollTo('certificates')}>Certyfikaty</Button>
                <Button size={isSmallScreen ? "small" : isScrolled ? "medium" : "large"}
                        onClick={() => handleScrollTo('contact')}>Kontakt</Button>
            </NavLinks>
        </NavbarDiv>
    );
};
