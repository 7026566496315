import {EntityType} from "../models/church-entity.enum";

export const churchEntitiesMock = [
    {id: 1, name: 'Archidiecezja Białostocka', type: EntityType.Diocese},
    {id: 2, name: 'Diecezja Bielsko-Żywiecka', type: EntityType.Diocese},
    {id: 3, name: 'Diecezja Bydgoska', type: EntityType.Diocese},
    {id: 4, name: 'Archidiecezja Częstochowska', type: EntityType.Diocese},
    {id: 5, name: 'Diecezja Drohiczyńska', type: EntityType.Diocese},
    {id: 6, name: 'Diecezja Elbląska', type: EntityType.Diocese},
    {id: 7, name: 'Diecezja Ełcka', type: EntityType.Diocese},
    {id: 8, name: 'Archidiecezja Gdańska', type: EntityType.Diocese},
    {id: 9, name: 'Diecezja Gliwicka', type: EntityType.Diocese},
    {id: 10, name: 'Archidiecezja Gnieźnieńska', type: EntityType.Diocese},
    {id: 11, name: 'Diecezja Kaliska', type: EntityType.Diocese},
    {id: 12, name: 'Archidiecezja Katowicka', type: EntityType.Diocese},
    {id: 13, name: 'Diecezja Kielecka', type: EntityType.Diocese},
    {id: 14, name: 'Diecezja Koszalińsko-Kołobrzeska', type: EntityType.Diocese},
    {id: 15, name: 'Archidiecezja Krakowska', type: EntityType.Diocese},
    {id: 16, name: 'Diecezja Legnicka', type: EntityType.Diocese},
    {id: 17, name: 'Archidiecezja Lubelska', type: EntityType.Diocese},
    {id: 18, name: 'Diecezja Łomżyńska', type: EntityType.Diocese},
    {id: 19, name: 'Diecezja Łowicka', type: EntityType.Diocese},
    {id: 20, name: 'Archidiecezja Łódzka', type: EntityType.Diocese},
    {id: 21, name: 'Eparchia Olsztyńsko-Gdańska', type: EntityType.Diocese},
    {id: 22, name: 'Diecezja Opolska', type: EntityType.Diocese},
    {id: 23, name: 'Diecezja Pelplińska', type: EntityType.Diocese},
    {id: 24, name: 'Diecezja Płocka', type: EntityType.Diocese},
    {id: 25, name: 'Archidiecezja Poznańska', type: EntityType.Diocese},
    {id: 26, name: 'Archidiecezja Przemyska', type: EntityType.Diocese},
    {id: 27, name: 'Archieparchia Przemysko-Warszawska', type: EntityType.Diocese},
    {id: 28, name: 'Diecezja Radomska', type: EntityType.Diocese},
    {id: 29, name: 'Diecezja Rzeszowska', type: EntityType.Diocese},
    {id: 30, name: 'Diecezja Sandomierska', type: EntityType.Diocese},
    {id: 31, name: 'Diecezja Siedlecka', type: EntityType.Diocese},
    {id: 32, name: 'Diecezja Sosnowiecka', type: EntityType.Diocese},
    {id: 33, name: 'Archidiecezja Szczecińsko-Kamieńska', type: EntityType.Diocese},
    {id: 34, name: 'Diecezja Świdnicka', type: EntityType.Diocese},
    {id: 35, name: 'Diecezja Tarnowska', type: EntityType.Diocese},
    {id: 36, name: 'Diecezja Toruńska', type: EntityType.Diocese},
    {id: 37, name: 'Archidiecezja Warmińska', type: EntityType.Diocese},
    {id: 38, name: 'Archidiecezja Warszawska', type: EntityType.Diocese},
    {id: 39, name: 'Diecezja Warszawsko-Praska', type: EntityType.Diocese},
    {id: 40, name: 'Diecezja Włocławska', type: EntityType.Diocese},
    {id: 41, name: 'Archidiecezja Wrocławska', type: EntityType.Diocese},
    {id: 42, name: 'Eparchia Wrocławsko-Koszalińska', type: EntityType.Diocese},
    {id: 43, name: 'Diecezja Zamojsko-Lubaczowska', type: EntityType.Diocese},
    {id: 44, name: 'Diecezja Zielonogórsko-Gorzowska', type: EntityType.Diocese},
    {id: 45, name: 'Parafia św. Katarzyny Aleksandryjskiej Męczennicy – Zgierz', type: EntityType.Parish},
    {id: 46, name: 'Parafia św. Maksymiliana Marii Kolbego – Zezulin', type: EntityType.Parish},
    {id: 47, name: 'Parafia Matki Bożej Dobrej Rady – Zgierz', type: EntityType.Parish},
    {id: 48, name: 'Parafia św. Jana Pawła II – Zgierz', type: EntityType.Parish},
    {id: 49, name: 'Parafia Narodzenia Najświętszej Maryi Panny – Żerniki', type: EntityType.Parish},
    {id: 50, name: 'Parafia Najświętszego Serca Pana Jezusa – Żerniki Wrocławskie', type: EntityType.Parish},
    {id: 51, name: 'Parafia Podwyższenia Krzyża Świętego – Żeszczynka', type: EntityType.Parish},
    {id: 52, name: 'Parafia św. Stanisława Biskupa – Żerków', type: EntityType.Parish},
    {id: 53, name: 'Parafia św. Michała Archanioła – Żernica', type: EntityType.Parish},
    {id: 54, name: 'Parafia św. Jana Chrzciciela – Zembrzyce', type: EntityType.Parish},
    {id: 55, name: 'Parafia św. Bartłomieja – Zeńbok', type: EntityType.Parish},
    {id: 56, name: 'Parafia św. Stanisława Biskupa i Męczennika – Zendek', type: EntityType.Parish},
    {id: 57, name: 'Parafia Matki Boskiej Częstochowskiej – Zelów', type: EntityType.Parish},
    {id: 58, name: 'Parafia św. Mikołaja – Zemborzyn Kościelny', type: EntityType.Parish},
    {id: 59, name: 'Parafia Najświętszej Maryi Panny Królowej Polski – Zembry', type: EntityType.Parish},
    {id: 60, name: 'Parafia św. Jana Nepomucena – Żeliszów', type: EntityType.Parish},
    {id: 61, name: 'Parafia Matki Boskiej Gietrzwałdzkiej – Zelki', type: EntityType.Parish},
    {id: 62, name: 'Parafia Trójcy Świętej – Żeliszew Podkościelny', type: EntityType.Parish},
    {id: 63, name: 'Parafia św. Wojciecha – Żelisławki', type: EntityType.Parish},
    {id: 64, name: 'Parafia Najświętszego Serca Pana Jezusa – Żelistrzewo', type: EntityType.Parish},
    {id: 65, name: 'Parafia św. Mikołaja – Żelichowo', type: EntityType.Parish},
    {id: 66, name: 'Parafia św. Zygmunta – Żelichów', type: EntityType.Parish},
    {id: 67, name: 'Parafia św. Jana Chrzciciela – Żelisławice', type: EntityType.Parish},
    {id: 68, name: 'Parafia Zwiastowania Najświętszej Maryi Panny – Żelechów', type: EntityType.Parish},
    {id: 69, name: 'Parafia św. Michała Archanioła – Żeleźnikowa Wielka', type: EntityType.Parish},
    {id: 70, name: 'Parafia św. Barbary – Żelazny Most', type: EntityType.Parish},
    {id: 71, name: 'Parafia Nawiedzenia Najświętszej Maryi Panny – Żelazowice', type: EntityType.Parish},
    {id: 72, name: 'Parafia św. Bartłomieja Apostoła – Żelechlinek', type: EntityType.Parish},
    {id: 73, name: 'Parafia Wszystkich Świętych – Żelazna', type: EntityType.Parish},
    {id: 74, name: 'Parafia św. Marcina – Żelazno', type: EntityType.Parish},
    {id: 75, name: 'Parafia Świętej Rodziny – Żelazna Góra', type: EntityType.Parish},
    {id: 76, name: 'Parafia św. Jadwigi – Żelazna', type: EntityType.Parish},
    {id: 77, name: 'Parafia Matki Boskiej Częstochowskiej – Zegrze Pomorskie', type: EntityType.Parish},
    {id: 78, name: 'Parafia św. Jana Ewangelisty – Żegoty', type: EntityType.Parish},
    {id: 79, name: 'Parafia św. Gabriela Archanioła – Zegrze', type: EntityType.Parish},
    {id: 80, name: 'Parafia św. Kingi – Żegiestów Zdrój', type: EntityType.Parish},
    {id: 81, name: 'Parafia Matki Boskiej Częstochowskiej – Żeglce', type: EntityType.Parish},
    {id: 82, name: 'Parafia Wniebowzięcia Najświętszej Maryi Panny – Żegocin', type: EntityType.Parish},
    {id: 83, name: 'Parafia św. Mikołaja Biskupa – Żegocina', type: EntityType.Parish},
    {id: 84, name: 'Parafia Wniebowzięcia Najświętszej Maryi Panny – Zebrzydowice', type: EntityType.Parish},
    {id: 85, name: 'Parafia Matki Boskiej Bolesnej – Żędowice', type: EntityType.Parish},
    {id: 86, name: 'Parafia św. Anny – Żegiestów', type: EntityType.Parish},
    {id: 87, name: 'Parafia NSPJ – Zębowo', type: EntityType.Parish},
    {id: 88, name: 'Parafia św. Michała Archanioła – Zebrzydowice', type: EntityType.Parish},
    {id: 89, name: 'Parafia Wniebowzięcia Najświętszej Maryi Panny – Godziszów', type: EntityType.Parish},
    {id: 90, name: 'Parafia św. Stanisława Biskupa i Męczennika – Żębocin', type: EntityType.Parish},
    {id: 91, name: 'Parafia Wniebowzięcia Najświętszej Maryi Panny – Zębowice', type: EntityType.Parish},
    {id: 92, name: 'Parafia św. Antoniego – Zdzieszowice', type: EntityType.Parish},
    {id: 93, name: 'Parafia św. Ojca Pio – Zdzieszowice', type: EntityType.Parish},
    {id: 94, name: 'Parafia Najświętszego Serca Pana Jezusa – Zdziechowice', type: EntityType.Parish},
    {id: 95, name: 'Parafia św. Mikołaja Biskupa – Żdżary', type: EntityType.Parish},
    {id: 96, name: 'Parafia Nawiedzenia Najświętszej Maryi Panny – Zdziarzec', type: EntityType.Parish},
    {id: 97, name: 'Parafia Najświętszej Maryi Panny Nieustającej Pomocy – Zdziechowa', type: EntityType.Parish},
    {id: 98, name: 'Parafia św. Jana Chrzciciela – Zduny', type: EntityType.Parish},
    {id: 99, name: 'Parafia Świętego Bartłomieja Apostoła – Żdżary', type: EntityType.Parish},
    {id: 100, name: 'Parafia Matki Bożej Nieustającej Pomocy – Rektorat – Żdżary', type: EntityType.Parish},
    {id: 101, name: 'Parafia św. Maksymiliana Marii Kolbe – Zduńska Wola', type: EntityType.Parish},
    {id: 102, name: 'Parafia Najświętszego Serca Pana Jezusa – Zduńska Wola', type: EntityType.Parish},
    {id: 103, name: 'Parafia św. Pawła Apostoła – Zduńska Wola', type: EntityType.Parish},
    {id: 104, name: 'Parafia św. Mateusza Apostoła i Ewangelisty – Zdroje', type: EntityType.Parish},
    {id: 105, name: 'Parafia Wniebowzięcia Najświętszej Maryi Panny – Zduńska Wola', type: EntityType.Parish},
    {id: 106, name: 'Parafia św. Antoniego z Padwy – Zduńska Wola', type: EntityType.Parish},
    {id: 107, name: 'Parafia Matki Boskiej Szkaplerznej – Zbylutów', type: EntityType.Parish},
    {id: 108, name: 'Parafia św. Bartłomieja Apostoła – Zbyszyce', type: EntityType.Parish},
    {id: 109, name: 'Parafia św. Stanisława Biskupa i Męczennika – Zdrochec', type: EntityType.Parish},
    {id: 110, name: 'Parafia św. Stanisława Biskupa i Męczennika – Zbuczyn', type: EntityType.Parish},
    {id: 111, name: 'Parafia Podwyższenia Krzyża Świętego – Zbydniów', type: EntityType.Parish},
    {id: 112, name: 'Parafia Podwyższenia Krzyża Świętego – Zbylitowska Góra', type: EntityType.Parish},
    {id: 113, name: 'Parafia św. Wojciecha Biskupa i Męczennika – Zbrachlin', type: EntityType.Parish},
    {id: 114, name: 'Parafia Wniebowzięcia Najświętszej Maryi Panny – Zbrosławice', type: EntityType.Parish},
    {id: 115, name: 'Parafia św. Jana Chrzciciela – Zbrosza Duża', type: EntityType.Parish},
    {id: 116, name: 'Parafia św. Marii Magdaleny – Zborowice', type: EntityType.Parish},
    {id: 117, name: 'Parafia Podwyższenia Krzyża Świętego – Zborowskie', type: EntityType.Parish},
    {id: 118, name: 'Parafia Matki Bożej Bolesnej – Żbikowice', type: EntityType.Parish},
    {id: 119, name: 'Parafia św. Michała Archanioła – Zblewo', type: EntityType.Parish},
    {id: 120, name: 'Parafia św. Idziego Opata – Zborówek', type: EntityType.Parish},
    {id: 121, name: 'Parafia Narodzenia Najświętszej Maryi Panny – Zbarzewo', type: EntityType.Parish},
    {id: 122, name: 'Parafia Najświętszej Maryi Panny Wniebowziętej – Zbąszyń', type: EntityType.Parish},
    {id: 123, name: 'Parafia Macierzyństwa Najświętszej Maryi Panny – Zbąszynek', type: EntityType.Parish},
    {id: 124, name: 'Parafia św. Józefa Robotnika – Zawoja', type: EntityType.Parish},
    {id: 125, name: 'Parafia św. Jadwigi Śląskiej – Zawonia', type: EntityType.Parish},
    {id: 126, name: 'Parafia Matki Bożej Nieustającej Pomocy – Zawoja Górna', type: EntityType.Parish},
    {id: 127, name: 'Parafia św. Józefa Robotnika – Zawiść', type: EntityType.Parish},
    {id: 128, name: 'Parafia św. Marii Magdaleny – Zawiszyce', type: EntityType.Parish},
    {id: 129, name: 'Parafia Świętego Klemensa I Papieża i Męczennika – Zawoja', type: EntityType.Parish},
    {id: 130, name: 'Parafia Miłosierdzia Bożego – Zawiercie', type: EntityType.Parish},
    {id: 131, name: 'Parafia św. Stanisława Kostki – Zawiercie', type: EntityType.Parish},
    {id: 132, name: 'Parafia św. Wojciecha Biskupa i Męczennika – Zawiercie', type: EntityType.Parish},
    {id: 133, name: 'Parafia Najświętszego Ciała i Krwi Chrystusa – Zawiercie', type: EntityType.Parish},
    {id: 134, name: 'Parafia św. Jana Pawła II – Zawiercie', type: EntityType.Parish},
    {id: 135, name: 'Parafia Najświętszej Maryi Panny Królowej Polski – Zawiercie', type: EntityType.Parish},
    {id: 136, name: 'Parafia św. Apostołów Piotra i Pawła – Zawiercie', type: EntityType.Parish},
    {id: 137, name: 'Parafia Trójcy Przenajświętszej – Zawiercie', type: EntityType.Parish},
    {id: 138, name: 'Parafia św. Brata Alberta Chmielowskiego – Zawiercie', type: EntityType.Parish},
    {id: 139, name: 'Parafia św. Mikołaja Biskupa i Męczennika – Zawiercie', type: EntityType.Parish},
    {id: 140, name: 'Parafia św. Maksymiliana Marii Kolbego – Zawiercie', type: EntityType.Parish},
    {id: 141, name: 'Parafia św. Antoniego Padewskiego – Zawieprzyce', type: EntityType.Parish},
    {id: 142, name: 'Parafia św. Józefa Robotnika – Zawidów', type: EntityType.Parish},
    {id: 143, name: 'Parafia św. Marcina – Zawidz Kościelny', type: EntityType.Parish},
    {id: 144, name: 'Parafia Świętej Rodziny – Zawadzkie', type: EntityType.Parish},
    {id: 145, name: 'Parafia Wniebowzięcia Najświętszej Maryi Panny – Zawichost', type: EntityType.Parish},
    {id: 146, name: 'Rektorat św. Jana Chrzciciela – Zawichost', type: EntityType.Parish},
    {id: 147, name: 'Parafia św. Floriana – Zawada', type: EntityType.Parish},
    {id: 148, name: 'Parafia Miłosierdzia Bożego – Zawadka Osiecka', type: EntityType.Parish},
    {id: 149, name: 'Parafia Najświętszego Serca Pana Jezusa – Zawadzkie', type: EntityType.Parish},
    {id: 150, name: 'Parafia Narodzenia Najświętszej Maryi Panny – Dębica', type: EntityType.Parish},
    {id: 151, name: 'Parafia św. Apostołów Piotra i Pawła – Zawada', type: EntityType.Parish},
    {id: 152, name: 'Parafia Narodzenia Matki Boskiej – Zielona Góra', type: EntityType.Parish},
    {id: 153, name: 'Parafia św. Małgorzaty – Zatory', type: EntityType.Parish},
    {id: 154, name: 'Parafia św. Józefa Robotnika – Zawada Książęca', type: EntityType.Parish},
    {id: 155, name: 'Parafia Podwyższenia Świętego Krzyża – Zawada', type: EntityType.Parish},
    {id: 156, name: 'Parafia św. Stanisława Biskupa i Męczennika – Zasów', type: EntityType.Parish},
    {id: 157, name: 'Parafia Świętych Wojciecha i Jerzego – Zator', type: EntityType.Parish},
    {id: 158, name: 'Parafia św. Wojciecha – Zaskale', type: EntityType.Parish},
    {id: 159, name: 'Parafia św. Józefa Oblubieńca Najświętszej Maryi Panny – Zarzecze', type: EntityType.Parish},
    {id: 160, name: 'Parafia św. Michała Archanioła – Zarzecze', type: EntityType.Parish}
]
