import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../styles/image-gallery-custom.css'; // Import niestandardowych stylów
import cert1 from "../assets/certs/c01.jpg";
import cert2 from "../assets/certs/c02.jpg";
import cert3 from "../assets/certs/c03.jpg";
import cert4 from "../assets/certs/c04.jpg";
import cert5 from "../assets/certs/c05.jpg";
import {Typography} from "@mui/material";

const images = [
    {
        original: cert1,
        thumbnail: cert1,
    },
    {
        original: cert2,
        thumbnail: cert2,
    },
    {
        original: cert3,
        thumbnail: cert3,
    },
    {
        original: cert4,
        thumbnail: cert4,
    },
    {
        original: cert5,
        thumbnail: cert5,
    },
];

const MyImageGallery: React.FC = () => {
    return (
        <div>
            <Typography variant="h3"
                        sx={{marginBottom: '48px', marginTop: '64px', fontWeight: 'bold', textAlign: 'center'}}>
                Zaświadczenia i Certyfikaty
            </Typography>
            <ImageGallery
                items={images}
                showThumbnails={true}
                showFullscreenButton={true}
                showPlayButton={false}
                showBullets={true}
                thumbnailPosition="bottom"
                additionalClass="custom-image-gallery-container"
            />
        </div>
    );
};

export default MyImageGallery;
