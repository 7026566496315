import styled from "styled-components";


export const AdvancesContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch; /* Ustawienie wszystkich elementów na tę samą wysokość */
    gap: 7%;
    margin-top: -38px;
    padding: 128px 64px;
    background-color: #f9f9f9;
    @media (max-width: 1440px) {
        display: grid;
        grid-template-columns: 100%;
        justify-items: center;
    }
`;

export const AdvancesItem = styled.div`
    background: #ffffff;
    border-radius: 16px;
    padding: 1.7% 2.3%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    flex: 1; /* Każda karta będzie się rozciągać, aby miała tę samą wysokość */
    display: flex;
    flex-direction: column; /* Umożliwia równomierne rozmieszczenie wewnętrznych elementów */
    justify-content: flex-start;

    &:hover {
        transform: translateY(-3px);
    }
`;

export const IconWrapper = styled.div`
    background: linear-gradient(45deg, ${({theme}) => theme.palette.primary.main}, ${({theme}) => theme.palette.secondary.main});
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 32px auto;
    border-radius: 50%;
    padding: 20px;
    color: white;
    width: 120px;
    height: 120px;
`;

export const AdvancesTitle = styled.h3`
    margin-top: 10px;
    font-size: 24px;
    color: ${({theme}) => theme.palette.text.primary};
`;

export const AdvancesText = styled.p`
    margin-top: 10px;
    color: ${({theme}) => theme.palette.text.secondary};
    font-size: 16px;
    line-height: 1.6;
`;
