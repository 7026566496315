import React from 'react';
import {Box, Card, CardContent, Grid, Typography} from '@mui/material';
import {styled} from '@mui/system';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleIcon from '@mui/icons-material/People';
import BalanceIcon from '@mui/icons-material/Balance';

const categories = [
    {
        title: 'Księgi handlowe',
        icon: <AssignmentIcon sx={{color: '#ffffff', fontSize: '40px'}}/>,
        items: [
            'formalna i rachunkowa kontrola dokumentów księgowych',
            'dekretacja',
            'wprowadzenie dokumentów do ksiąg i rejestrów VAT',
            'prowadzenie ewidencji środków trwałych i wyposażenia',
            'sporządzanie, na podstawie ksiąg i ewidencji, miesięcznych deklaracji VAT oraz obliczanie wysokości zaliczek na podatek dochodowy',
            'sporządzanie rocznych sprawozdań finansowych',
        ],
    },
    {
        title: 'Księga przychodów i rozchodów',
        subtitle: '(ewidencja przychodów - ryczałt)',
        icon: <AccountBalanceIcon sx={{color: '#ffffff', fontSize: '40px'}}/>,
        items: [
            'formalna i rachunkowa kontrola dokumentów księgowych',
            'wprowadzenie dokumentów do ksiąg i rejestrów VAT',
            'prowadzenie ewidencji środków trwałych i wyposażenia',
            'sporządzanie, na podstawie ksiąg i ewidencji, miesięcznych deklaracji VAT oraz obliczanie wysokości zaliczek na podatek dochodowy',
            'sporządzanie rocznych deklaracji PIT-36 rozliczających właścicieli',
        ],
    },
    {
        title: 'Kadry i płace',
        icon: <PeopleIcon sx={{color: '#ffffff', fontSize: '40px'}}/>,
        items: [
            'sporządzanie list płac',
            'sporządzanie miesięcznych deklaracji rozliczeniowych ZUS',
            'wyliczenie comiesięcznej zaliczki na podatek dochodowy od osób fizycznych',
            'sporządzanie informacji o uzyskanych dochodach oraz o pobranych zaliczkach na podatek dochodowy od osób fizycznych (PIT-11, PIT-4R)',
            'sporządzanie dokumentacji związanych z zatrudnieniem lub zwalnianiem pracowników (umowy, świadectwa pracy), wystawienie zaświadczeń o zatrudnieniu i zarobkach',
            'kompleksowe prowadzenie akt osobowych',
            'obsługa osób zatrudnionych w ramach umów cywilnoprawnych (sporządzanie umów i rachunków)',
        ],
    },
    {
        title: 'Inne',
        icon: <BalanceIcon sx={{color: '#ffffff', fontSize: '40px'}}/>,
        items: [
            'sporządzanie deklaracji dla takich instytucji jak NBP',
            'pomoc w rejestracji i zamknięciu działalności',
            'możliwość księgowania w siedzibie klienta',
            'rozliczanie dotacji UE',
            'rozliczenie z podatku VAT rolników',
            'sporządzanie sprawozdań dla Wojewódzkiego Urzędu Statystycznego i Głównego Urzędu Statystycznego',
        ],
    },
];


const OfferCard = styled(Card)({
    background: '#ffffff',
    borderRadius: '16px',
    padding: '2.7% 3.3%',
    maxWidth: '550px',
    textAlign: 'left',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.07)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '32px',
    justifyContent: 'flex-start',
    margin: '0 auto',
    '&:hover': {
        transform: 'translateY(-3px)',
    },
});

const TitleContainer = styled(Box)({
    backgroundColor: '#937d50',
    color: '#ffffff',
    padding: '16px',
    borderRadius: '16px 16px 0 0',
    margin: '0',  // Zmniejszony margines i usunięty dolny margines
    textAlign: 'center',
    minHeight: '80px',  // Ustalenie wysokości kontenera tytułu
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

const OfferSection = () => {
    return (
        <Box sx={{padding: '40px 10% 86px 10%', backgroundColor: '#f5f5f5'}}>
            <Typography variant="h3"
                        sx={{marginBottom: '72px', marginTop: '32px', fontWeight: 'bold', textAlign: 'center'}}>
                Nasza Oferta
            </Typography>
            <Grid container spacing={4} justifyContent="center" sx={{
                rowGap: {
                    xs: '8px',
                    sm: '16px',
                    md: '24px',
                    lg: '32px',
                },
            }}>  {/* Wycentrowanie kart */}
                {categories.map((category, index) => (
                    <Grid item xs={12} sm={6} key={index} display="flex">  {/* Dwie kolumny */}
                        <OfferCard>
                            <TitleContainer>
                                {category.icon}
                                <Typography variant="h5" sx={{fontWeight: 'bold', marginTop: '8px'}}>
                                    {category.title}
                                </Typography>
                                {category.subtitle && (
                                    <Typography variant="subtitle1" sx={{fontStyle: 'italic'}}>
                                        {category.subtitle}
                                    </Typography>
                                )}
                            </TitleContainer>
                            <CardContent
                                sx={{
                                    flexGrow: 1,
                                    padding: '0px 14px 4px 14px !important;'
                                }}>  {/* Usunięcie paddingu */}
                                <ul style={{
                                    paddingLeft: '16px',
                                    marginTop: '0'
                                }}>  {/* Usunięcie górnego marginesu i paddingu */}
                                    {category.items.map((item, idx) => (
                                        <li key={idx} style={{color: '#937d50'}}>
                                            <Typography variant="body1" sx={{
                                                marginBottom: '12px',
                                                fontSize: '1.1rem',
                                                color: 'rgba(0, 0, 0, 0.8)'
                                            }}>
                                                {item}
                                            </Typography>
                                        </li>
                                    ))}
                                </ul>
                            </CardContent>
                        </OfferCard>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default OfferSection;
