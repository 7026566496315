import React, {useEffect, useRef, useState} from 'react';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Snackbar,
    TextField,
    useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import BeenhereIcon from '@mui/icons-material/Beenhere';

const ContactDialog = ({open, onClose, onMessageSent, isMessageSent}: any) => {
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [emailTouched, setEmailTouched] = useState<boolean>(false);
    const [messageTouched, setMessageTouched] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const theme = useTheme();
    const emailInputRef = useRef<HTMLInputElement>(null);

    const emailError = emailTouched && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
    const messageError = messageTouched && message.trim().length === 0;

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                emailInputRef?.current?.focus();
            }, 100);
        }
    }, [open]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleEmailBlur = () => {
        setEmailTouched(true);
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value);
    };

    const handleMessageBlur = () => {
        setMessageTouched(true);
    };

    const handleClose = () => {
        setEmail('');
        setMessage('');
        setEmailTouched(false);
        setMessageTouched(false);
        onClose();
    };

    const handleSubmit = () => {
        if (email && message && !emailError && !messageError) {
            onMessageSent();  // Wywołanie callbacku aby poinformować nadrzędny komponent
            const mailtoLink = `mailto:biuro@profit-pila.pl?subject=Kontakt%20z%20Twojej%20strony&body=${encodeURIComponent(message)}&from=${encodeURIComponent(email)}`;
            window.location.href = mailtoLink;

            setTimeout(() => {
                handleClose();
                setSnackbarOpen(true);  // Wyświetlenie snackbara po wysłaniu
            }, 500); // Zamknięcie dialogu po 2 sekundach
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={(event, reason) => reason !== 'backdropClick' && onClose()}
                PaperProps={{
                    sx: {
                        elevation: 4,
                        borderRadius: '12px',
                        padding: '0px',
                        overflow: 'visible',
                        maxWidth: '500px',
                        width: '100%',
                        height: 'fit-content',
                    }
                }}
            >
                <DialogTitle
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        padding: '28px 24px',
                        fontSize: '22px',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderRadius: '12px 12px 0 0'
                    }}
                >
                    Wyślij wiadomość
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        sx={{color: theme.palette.common.white}}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{padding: '12px 24px', gap: '12px', marginBottom: '0', marginTop: '36px'}}>
                    <TextField
                        margin="dense"
                        inputRef={emailInputRef}
                        label="Twój email"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        onBlur={handleEmailBlur}
                        error={emailError}
                        helperText={emailError ? "Podaj poprawny adres email" : " "}
                        sx={{marginBottom: '16px'}}
                    />
                    <TextField
                        margin="dense"
                        label="Twoja wiadomość"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={message}
                        onChange={handleMessageChange}
                        onBlur={handleMessageBlur}
                        error={messageError}
                        helperText={messageError ? "Wiadomość nie może być pusta" : " "}
                        sx={{marginBottom: '8px'}}
                    />
                </DialogContent>
                <DialogActions sx={{padding: '16px 24px', gap: '8px', justifyContent: 'space-between'}}>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="secondary"
                        sx={{
                            textTransform: 'none',
                            padding: '8px 16px',
                            fontWeight: 500
                        }}
                    >
                        Anuluj
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={emailError || messageError || !email || !message || isMessageSent}
                        sx={{
                            textTransform: 'none',
                            padding: '8px 16px',
                            fontWeight: 500
                        }}
                        endIcon={<SendIcon/>}
                    >
                        Wyślij
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: "bottom", horizontal: 'right'}}  // Snackbar w prawym górnym rogu
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    sx={{
                        width: '100%',
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        display: 'flex',
                        alignItems: 'center',
                        wordBreak: 'break-word', // Złamanie linii po "!"
                        textAlign: 'center'
                    }}
                    iconMapping={{
                        success: <BeenhereIcon sx={{color: theme.palette.common.white}}/>
                    }}
                >
                    Wiadomość została przetworzona! <br/>Dokończ proces w swojej aplikacji pocztowej.
                </Alert>
            </Snackbar>
        </>
    );
};

export default ContactDialog;
