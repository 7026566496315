import React from 'react';
import {Box, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material';
import {styled} from '@mui/system';
import VerifiedIcon from '@mui/icons-material/Verified';
import misc2 from '../assets/miscs/2.jpg';

const BackgroundImage = styled(Box)(({theme}) => ({
    backgroundImage: `url(${misc2})`,
    backgroundSize: '90%',  // Obrazek będzie dopasowany, aby cały zmieścił się w sekcji
    backgroundRepeat: 'no-repeat',  // Zapobiega powtarzaniu się obrazka
    backgroundPosition: 'right 13% center !important',  // Przesunięcie obrazka, aby respektował padding
    width: '100%',
    padding: '64px 6%',  // Padding 80px z góry i z dołu, 10% z lewej i prawej
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box',
    [theme.breakpoints.up('lg')]: {
        backgroundSize: '66%',  // Zmniejszenie obrazka na większych ekranach
        backgroundPosition: 'right 10%',  // Przesunięcie obrazu, aby respektował padding
    },
}));


const TextContainer = styled(Box)({
    maxWidth: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',  // Subtelne tło, aby tekst był czytelny
    padding: '20px',
    borderRadius: '8px',
});

const Title = styled(Typography)`
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    color: #333;
    text-align: left;
    white-space: nowrap;
    margin-bottom: 45px;

    @media (max-width: 1440px) {
        font-size: 2.2rem;
        margin-bottom: 35px;
    }

    @media (max-width: 768px) {
        font-size: 1.2rem;
        margin-bottom: 15px;
    }
`;
const Subtitle = styled(Typography)({
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: 1.6,
    color: '#555',
    marginTop: '16px',
    marginBottom: '12px !important'
});

const StyledListItem = styled(ListItem)({
    padding: 0,
    alignItems: 'center',  // Poprawione wyjustowanie w pionie
});

const ImageSection = () => {
    return (
        <BackgroundImage>
            <TextContainer>
                <Title>
                    Szybko, profesjonalnie, <br/> z myślą o Twoich potrzebach
                </Title>
                <Subtitle>
                    Nasze biuro prowadzą wykwalifikowane księgowe z wieloletnim doświadczeniem:
                </Subtitle>
                <List>
                    <StyledListItem>
                        <ListItemIcon sx={{minWidth: 'auto', marginRight: '12px'}}>
                            <VerifiedIcon sx={{color: '#2e7d32', fontSize: '28px', verticalAlign: 'middle'}}/>
                        </ListItemIcon>
                        <ListItemText
                            primary="Dorota Leska"
                            secondary="Licencja Ministra Finansów nr 17488/2007"
                            primaryTypographyProps={{fontWeight: 'bold', color: '#333', fontSize: '1.1rem'}}
                            secondaryTypographyProps={{color: '#666', fontSize: '0.9rem'}}
                        />
                    </StyledListItem>
                    <StyledListItem>
                        <ListItemIcon sx={{minWidth: 'auto', marginRight: '12px'}}>
                            <VerifiedIcon sx={{color: '#2e7d32', fontSize: '28px', verticalAlign: 'middle'}}/>
                        </ListItemIcon>
                        <ListItemText
                            primary="Magdalena Jankowska"
                            secondary="Licencja Ministra Finansów nr 6690/2004"
                            primaryTypographyProps={{fontWeight: 'bold', color: '#333', fontSize: '1.1rem'}}
                            secondaryTypographyProps={{color: '#666', fontSize: '0.9rem'}}
                        />
                    </StyledListItem>
                </List>
                <Subtitle>
                    Zapewniamy pełne wsparcie w zakresie księgowości oraz współpracujemy z radcą prawnym Andrzejem
                    Czyżykiem, aby zapewnić naszym klientom kompleksowe usługi.
                </Subtitle>
            </TextContainer>
        </BackgroundImage>
    );
};

export default ImageSection;
